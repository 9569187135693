import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import cx from 'classnames';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Toggle from 'react-toggle';
import _find from 'lodash/find';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { Link } from '@zola/zola-ui/src/components/Link';
import { Error } from '@zola/zola-ui/src/components/Form/Error';
import { MessageContainer } from '@zola/zola-ui/src/components/Form/form.styles';
import Message from '@zola/zola-ui/src/components/Form/common/Message/Message';

import '../../../stylesheets/util/_form.less';
import '../../../stylesheets/util/_dayPicker.less';

export const RenderField = props => {
  const {
    input,
    elementIdx,
    fieldIdx,
    label,
    labelClass,
    type,
    id,
    required,
    placeholder,
    disabled,
    inputGroup,
    onBlurFunction,
    onChangeFunction,
    maxChars,
    className,
    hideFormGroup,
    helpMessage,
    showCharacterCount,
    meta: { pristine, dirty, touched, error, warning, asyncValidating, initial },
    autoFocus,
    onKeyDown,
    autoComplete,
    errorOverride,
  } = props;

  const isSlugAvailable =
    touched &&
    !pristine &&
    dirty &&
    input.name === 'mprSlug' &&
    initial !== input.value &&
    !asyncValidating &&
    !error &&
    !warning;

  const classes = cx(
    {
      'form-group': !hideFormGroup,
      'has-error': (error && !required) || (error && required && touched),
      'slug-available': isSlugAvailable,
    },
    className
  );

  return (
    <div className={classes}>
      {label && (
        <label htmlFor={id} className={labelClass}>
          {label} {required && <span className="text-danger danger-asterisk">*</span>}
        </label>
      )}
      <div className={`${inputGroup ? 'input-group' : ''}`}>
        {inputGroup && <span className="input-group-addon">{inputGroup}</span>}
        <input
          {...input}
          className="form-control"
          type={type}
          id={id}
          data-elementidx={elementIdx}
          data-fieldidx={fieldIdx}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          onBlur={e => {
            if (onBlurFunction) {
              onBlurFunction(input.value);
            }
            input.onBlur(e);
          }}
          onChange={e => {
            if (onChangeFunction) {
              onChangeFunction(e);
            }
            input.onChange(e);
          }}
          onKeyDown={onKeyDown}
          autoComplete={autoComplete}
        />
        {maxChars && !inputGroup && (
          <span
            className={`help-block pull-right char-count text-${
              input.value.length <= maxChars ? 'muted' : 'danger'
            } ${
              input.value.length < maxChars - maxChars / 10 && !showCharacterCount ? 'hidden' : ''
            }`}
          >
            {input.value.length}/{maxChars}
          </span>
        )}
        {helpMessage && !inputGroup && <small className="text-muted">{helpMessage}</small>}
        {((error && !required) || (error && required && touched)) && (
          <span className="help-block">
            <i className="zolaicon zolaicon-error" />
            {error}
          </span>
        )}
        {warning && <span className="help-block text-muted">{warning}</span>}
        {errorOverride && (
          <span className="help-block error-block">
            <i className="zolaicon zolaicon-error" /> {errorOverride}
          </span>
        )}
        {asyncValidating && input.name !== 'url' && (
          <span className="help-block checking-availability-block">Checking availability...</span>
        )}
        {isSlugAvailable && (
          <span className="help-block mpr-edit-slug__available-block">
            <i className="zolaicon zolaicon-checkmark" />
            That one is available
          </span>
        )}
      </div>
      {maxChars && inputGroup && (
        <span
          className={`pull-right char-count text-${
            input.value.length <= maxChars ? 'muted' : 'danger'
          } ${
            input.value.length < maxChars - maxChars / 10 && !showCharacterCount ? 'hidden' : ''
          }`}
        >
          {input.value.length}/{maxChars}
        </span>
      )}
      {helpMessage && inputGroup && <small className="text-muted">{helpMessage}</small>}
    </div>
  );
};

RenderField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  labelClass: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'email',
    'password',
    'tel',
    'number',
    'url',
    'search',
    'date',
    'time',
  ]).isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  inputGroup: PropTypes.string,
  onBlurFunction: PropTypes.func,
  onChangeFunction: PropTypes.func,
  maxChars: PropTypes.number,
  className: PropTypes.string,
  hideFormGroup: PropTypes.bool,
  helpMessage: PropTypes.string,
  showCharacterCount: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
    asyncValidating: PropTypes.bool,
  }),
  autoFocus: PropTypes.bool,
  onKeyDown: PropTypes.func,
  autoComplete: PropTypes.string,
  elementIdx: PropTypes.number,
  fieldIdx: PropTypes.number,
  errorOverride: PropTypes.string,
};

// TODO: Refactor this entire thing. It probably needs to be 2 separate ones.
export const RenderCheckboxRadioField = ({
  input,
  label,
  labelElem,
  type,
  className,
  inline,
  id,
  styleOverride,
  boldActiveLabel,
  onChangeAction,
  disableDefaultAction,
  checkedOverride,
  checked,
  disabled,
  meta: { touched, error },
}) => {
  const inputAttr = checkedOverride ? Object.assign({}, input, { checked }) : { ...input };

  let html;

  if (inline) {
    html = (
      <label className={`${type}-inline ${className}`} htmlFor={id}>
        <input
          {...inputAttr}
          type={type}
          id={id}
          disabled={disabled}
          onChange={e => {
            if (onChangeAction) {
              onChangeAction();
            }
            if (!disableDefaultAction) {
              input.onChange(e);
            }
          }}
        />
        {styleOverride && (
          <span className="input-override">
            {type === 'checkbox' && <i className="zolaicon zolaicon-checkmark" />}
          </span>
        )}
        {label && label}
        {labelElem && labelElem}
      </label>
    );
  } else {
    html = (
      <div className={`${type} ${className} ${touched && error ? ' has-error' : ''}`}>
        <label htmlFor={id}>
          <input
            {...inputAttr}
            type={type}
            id={id}
            disabled={disabled}
            onChange={e => {
              if (onChangeAction) {
                onChangeAction();
              }
              if (!disableDefaultAction) {
                input.onChange(e);
              }
            }}
          />
          {styleOverride && (
            <span className="input-override">
              {type === 'checkbox' && <i className="zolaicon zolaicon-checkmark" />}
            </span>
          )}
          <span
            className={
              input.checked && boldActiveLabel ? 'input-label active-label' : 'input-label'
            }
          >
            {label && label}
            {labelElem && labelElem}
          </span>
        </label>
        {!styleOverride && touched && error && <span className="help-block">{error}</span>}
      </div>
    );
  }
  return html;
};

RenderCheckboxRadioField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
  }).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelElem: PropTypes.element,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  inline: PropTypes.bool,
  styleOverride: PropTypes.bool,
  onChangeAction: PropTypes.func,
  disableDefaultAction: PropTypes.bool,
  checkedOverride: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  boldActiveLabel: PropTypes.bool,
};

export const RenderSelectField = ({
  className,
  input,
  label,
  labelClass,
  options,
  id,
  required,
  hideEmpty,
  hideFormGroup,
  selectText,
  disabled,
  meta: { touched, error },
  onChangeFunction,
  autoComplete,
}) => {
  const hasError = (error && !required) || (error && required && touched);
  return (
    <div
      className={`${hideFormGroup ? '' : 'form-group'} ${hasError ? 'has-error' : ''} ${className}`}
    >
      {label && (
        <label htmlFor={id} className={labelClass}>
          {label} {required && <span className="text-danger danger-asterisk">*</span>}
        </label>
      )}
      <select
        {...input}
        className="form-control"
        id={id}
        disabled={disabled}
        onChange={e => {
          if (onChangeFunction) {
            onChangeFunction(e);
          }
          input.onChange(e);
        }}
        autoComplete={autoComplete}
      >
        {!hideEmpty && <option value="">{selectText || 'Select...'}</option>}
        {options.map(opt => (
          <option value={opt.value} key={opt.value}>
            {opt.title}
          </option>
        ))}
      </select>
      {hasError ? (
        <span className="help-block danger-text">
          <i className="zolaicon zolaicon-error" />
          {error}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

RenderSelectField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  hideEmpty: PropTypes.bool,
  hideFormGroup: PropTypes.bool,
  selectText: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChangeFunction: PropTypes.func,
  autoComplete: PropTypes.string,
};

export function RenderTextareaField({
  input,
  label,
  id,
  rows,
  cols,
  required,
  placeholder,
  maxChars,
  disabled,
  meta: { touched, error },
  tooltip,
  className,
  wrap,
  errorOverride,
  onKeyDown,
  autoFocus,
}) {
  return (
    <div className={`${className} ${touched && error ? 'form-group has-error' : 'form-group'}`}>
      {label && (
        <label htmlFor={id}>
          {label} {required && <span className="text-danger danger-asterisk">*</span>}
        </label>
      )}
      {tooltip && (
        <div className="with-tooltip" data-tooltip={tooltip}>
          <i className="zolaicon zolaicon-info" />
        </div>
      )}
      <textarea
        {...input}
        className="form-control"
        id={id}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        disabled={disabled}
        onKeyDown={onKeyDown}
        wrap={wrap}
        style={{ verticalAlign: 'top' }}
        autoFocus={autoFocus}
      />
      {maxChars && (
        <span
          className={`pull-right char-count text-${
            input.value.length <= maxChars ? 'muted' : 'danger'
          }`}
        >
          {input.value.length}/{maxChars}
        </span>
      )}
      {errorOverride && <span className="help-block error-block">{errorOverride}</span>}
      {touched && error && <span className="help-block">{error}</span>}
    </div>
  );
}

RenderTextareaField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  rows: PropTypes.number,
  cols: PropTypes.number,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  maxChars: PropTypes.number,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  tooltip: PropTypes.string,
  className: PropTypes.string,
  wrap: PropTypes.string,
  errorOverride: PropTypes.string,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
};

export const RenderSubmitButton = props => {
  const {
    pristine,
    submitting,
    valid,
    className,
    value,
    disableWhenPristine,
    showSuccess,
    disabled,
    compact,
    fullWidth,
    useButtonV1,
    type,
  } = props;
  const disable = disabled || !valid || showSuccess || (disableWhenPristine && pristine);
  const buttonText = showSuccess ? 'Saved' : value || 'Save';
  return (
    <Fragment>
      {submitting ? (
        <div className="busy-loader" />
      ) : (
        <React.Fragment>
          {useButtonV1 && (
            <button
              className={`btn btn-primary btn-wide ${className || ''}${disable ? ' disabled' : ''}`}
              disabled={disable}
            >
              {showSuccess && <i className="zolaicon zolaicon-checkmark" />} {buttonText}
            </button>
          )}
          {!useButtonV1 && (
            <ButtonV3
              className={className}
              disabled={disable}
              compact={compact}
              fullWidth={fullWidth}
              type={type}
            >
              {showSuccess && <i className="zolaicon zolaicon-checkmark" />} {buttonText}
            </ButtonV3>
          )}
        </React.Fragment>
      )}
    </Fragment>
  );
};

RenderSubmitButton.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  disableWhenPristine: PropTypes.bool,
  showSuccess: PropTypes.bool,
  disabled: PropTypes.bool,
  useButtonV1: PropTypes.bool,
  compact: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
};

RenderSubmitButton.defaultProps = {
  disableWhenPristine: false,
  showSuccess: false,
  submitting: false,
  type: 'submit',
};

export const RenderHiddenField = ({ input, disabled }) => (
  <input {...input} type="hidden" disabled={disabled} />
);

RenderHiddenField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  }),
  disabled: PropTypes.bool,
};

export class RenderDatePicker extends Component {
  constructor(props) {
    super(props);
    this.updateField = this.updateField.bind(this);
    this.updateInlineField = this.updateInlineField.bind(this);
  }

  updateField(date) {
    if (date === undefined) {
      return;
    }
    // eslint-disable-next-line dot-notation
    if (!date['_isValid']) {
      return;
    }
    this.props.input.onChange(date ? moment.utc(date) : null);
  }

  updateInlineField(date) {
    this.props.input.onChange(date ? moment.utc(date) : null);
  }

  render() {
    const {
      calendarIcon,
      input,
      datePickerOptions,
      disabled,
      placeholder,
      label,
      id,
      required,
      hideFormGroup,
      inline,
      meta: { touched, error },
      className,
      hideOnDayClick,
      useV2ErrorStyle,
    } = this.props;
    return (
      <div
        className={cx(className, { 'form-group': !hideFormGroup, 'has-error': touched && error })}
      >
        <label htmlFor={id}>
          {label} {required && <span className="text-danger danger-asterisk">*</span>}
        </label>
        {inline || (datePickerOptions && datePickerOptions.inline) ? (
          <div className={cx('zola-ui', 'zui__datepicker')}>
            <DayPicker
              selectedDays={
                moment(input.value, 'MM/DD/YYYY', true).isValid()
                  ? moment(
                      moment.utc(input.value, 'MM/DD/YYYY').format('MM/DD/YYYY'),
                      'MM/DD/YYYY',
                      true
                    ).toDate()
                  : undefined
              }
              initialMonth={
                moment(input.value, 'MM/DD/YYYY', true).isValid()
                  ? moment(
                      moment.utc(input.value, 'MM/DD/YYYY').format('MM/DD/YYYY'),
                      'MM/DD/YYYY',
                      true
                    ).toDate()
                  : undefined
              }
              onDayClick={this.updateInlineField}
              {...datePickerOptions}
            />
          </div>
        ) : (
          <div className={cx('zola-ui', 'zui__datepicker', 'daypicker-container')}>
            <DayPickerInput
              {...input}
              value={
                moment(input.value, 'MM/DD/YYYY', true).isValid()
                  ? moment.utc(input.value, 'MM/DD/YYYY').format('MM/DD/YYYY')
                  : input.value
              }
              className="form-control"
              format="MM/DD/YYYY"
              placeholder={placeholder}
              disabled={disabled}
              id={id}
              hideOnDayClick={hideOnDayClick}
              dayPickerProps={{ fixedWeeks: true, onBlur: this.updateField, ...datePickerOptions }}
              onDayChange={this.updateField}
              autoComplete="off"
            />
            {calendarIcon && <i className="zolaicon-calendar" style={{ pointerEvents: 'none' }} />}
          </div>
        )}
        {error &&
          (useV2ErrorStyle ? (
            <Error message={error} iconSize={12} />
          ) : (
            <span className="help-block">{error}</span>
          ))}
      </div>
    );
  }
}

RenderDatePicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  datePickerOptions: PropTypes.shape({}),
  hideFormGroup: PropTypes.bool,
  inline: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  calendarIcon: PropTypes.bool,
  className: PropTypes.string,
  hideOnDayClick: PropTypes.bool,
  useV2ErrorStyle: PropTypes.bool,
};

export class RenderTimePicker extends Component {
  constructor(props) {
    super(props);

    this.onWindowClick = this.onWindowClick.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.updateField = this.updateField.bind(this);
    this.setActiveField = this.setActiveField.bind(this);
    this.hide = this.hide.bind(this);

    const {
      name,
      value: { hour, minute },
    } = props.input;
    this.state = {
      isOpen: false,
      activeField: `${name}-${hour}-${minute}`,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('touchstart', this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('touchstart', this.onWindowClick);
  }

  onWindowClick(e) {
    const { isOpen } = this.state;
    const dropdownElement = findDOMNode(this);
    if (e.target !== dropdownElement && !dropdownElement.contains(e.target) && isOpen) {
      this.hide();
    }
  }

  setActiveField(activeField) {
    this.setState({
      activeField,
    });
  }

  hide() {
    this.setState({
      isOpen: false,
    });
  }

  toggleDropdown() {
    const {
      name,
      value: { hour, minute },
    } = this.props.input;
    const activeField = `${name}-${hour}-${minute}`;
    this.setState(
      state => ({
        isOpen: !state.isOpen,
        activeField,
      }),
      () => {
        const activeEl = document.getElementById(activeField);
        if (activeEl) {
          this.selectDropdown.scrollTop = activeEl.offsetTop - 80;
        }
      }
    );
  }

  updateField(value) {
    const { input } = this.props;
    this.props.input.onChange(value);
    this.setActiveField(`${input.name}-${value.hour}-${value.minute}`);
  }

  render() {
    const {
      className,
      input,
      label,
      id,
      required,
      hideFormGroup,
      maxHeight,
      meta: { touched, error },
    } = this.props;
    const { isOpen, activeField } = this.state;

    const times = [];
    for (let h = 0; h <= 23; h += 1) {
      times.push({ hour: h, minute: 0 });
      times.push({ hour: h, minute: 15 });
      times.push({ hour: h, minute: 30 });
      times.push({ hour: h, minute: 45 });
    }

    return (
      <div
        className={`${hideFormGroup ? '' : 'form-group'} ${
          touched && error ? 'has-error' : ''
        } ${className}`}
      >
        {label && (
          <label htmlFor={id}>
            {label} {required && <span className="text-danger danger-asterisk">*</span>}
          </label>
        )}
        <div id={id} className="scroll-select" onClick={() => this.toggleDropdown()}>
          <div className="btn btn-dropdown btn-boxed">
            {input.value === '' ? (
              <span className="scroll-value">Select...</span>
            ) : (
              <span className="scroll-value">
                {moment({ hour: input.value.hour, minute: input.value.minute }).format('h:mm a')}
              </span>
            )}
            <i className="zolaicon zolaicon-chevron-down" />
          </div>
          <div
            className="scroll-select-dropdown"
            style={{
              display: isOpen ? 'block' : 'none',
              maxHeight: maxHeight ? `${maxHeight}` : '200px',
            }}
            ref={selectDropdown => {
              this.selectDropdown = selectDropdown;
            }}
          >
            {!required && (
              <div
                id={`${input.name}-empty`}
                className={`scroll-select-option ${
                  activeField === `${input.name}-empty` ? 'scroll-select-active' : ''
                }`}
                onClick={() => this.updateField('')}
                onMouseEnter={() => this.setActiveField(`${input.name}-empty`)}
              >
                Select...
              </div>
            )}
            {times.map(option => (
              <div
                id={`${input.name}-${option.hour}-${option.minute}`}
                key={`${input.name}-${option.hour}-${option.minute}`}
                className={`scroll-select-option ${
                  activeField === `${input.name}-${option.hour}-${option.minute}`
                    ? 'scroll-select-active'
                    : ''
                }`}
                onClick={() => this.updateField(option)}
                onMouseEnter={() =>
                  this.setActiveField(`${input.name}-${option.hour}-${option.minute}`)
                }
              >
                {moment({ hour: option.hour, minute: option.minute }).format('h:mm a')}
              </div>
            ))}
          </div>
        </div>
        {error && (
          <MessageContainer>
            <Message type="error" text={error} className="error-message" />
          </MessageContainer>
        )}
      </div>
    );
  }
}

RenderTimePicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  hideFormGroup: PropTypes.bool,
  maxHeight: PropTypes.number,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  className: PropTypes.string,
};

export function RenderToggleField({
  input,
  label,
  tooltip,
  id,
  className,
  inline,
  hideFormGroup,
  disabled,
  meta: { touched, error },
}) {
  return (
    <div
      className={`${hideFormGroup ? '' : 'form-group'} ${
        touched && error ? 'has-error' : ''
      } ${className}`}
    >
      <div id={id} className="toggle">
        {tooltip ? (
          <div className="with-tooltip" data-tooltip={tooltip}>
            <span>{label}</span>
            <div className={`${inline ? 'toggle-button-inline' : ''}`}>
              <Toggle
                checked={!!input.value}
                disabled={disabled}
                onChange={() => input.onChange(!input.value)}
                className="toggle-small"
                icons={{
                  checked: <span className="toggle-label checked">Yes</span>,
                  unchecked: <span className="toggle-label unchecked">No</span>,
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <span>{label}</span>
            <div className={`${inline ? 'toggle-button-inline' : ''}`}>
              <Toggle
                checked={!!input.value}
                disabled={disabled}
                onChange={() => input.onChange(!input.value)}
                className="toggle-small"
                icons={{
                  checked: <span className="toggle-label checked">Yes</span>,
                  unchecked: <span className="toggle-label unchecked">No</span>,
                }}
              />
            </div>
          </div>
        )}
      </div>
      {touched && error && <span className="help-block">{error}</span>}
    </div>
  );
}

RenderToggleField.propTypes = {
  input: PropTypes.shape({}),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  hideFormGroup: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export const RenderError = ({ meta: { touched, error } }) => (
  <span>{touched && error && <span className="help-block">error</span>}</span>
);

RenderError.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export class RenderDropdownSelect extends Component {
  constructor(props) {
    super(props);

    this.onWindowClick = this.onWindowClick.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.updateField = this.updateField.bind(this);
    this.setActiveField = this.setActiveField.bind(this);
    this.findLabel = this.findLabel.bind(this);
    this.hide = this.hide.bind(this);

    const { value } = props.input;
    this.state = {
      isOpen: false,
      activeField: value,
      touchedOverride: props.touchedOverride || false,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('touchstart', this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('touchstart', this.onWindowClick);
  }

  onWindowClick(e) {
    const { isOpen } = this.state;
    const dropdownElement = findDOMNode(this);
    if (e.target !== dropdownElement && !dropdownElement.contains(e.target) && isOpen) {
      this.hide();
    }
  }

  setActiveField(activeField) {
    this.setState({
      activeField,
    });
  }

  findLabel(value) {
    const { options } = this.props;
    const selectedOption = _find(options, { value: value }); // eslint-disable-line object-shorthand
    if (selectedOption) {
      return selectedOption.title;
    }
    return null;
  }

  hide() {
    this.setState({
      isOpen: false,
    });
  }

  toggleDropdown() {
    const { value } = this.props.input;
    const activeField = value;
    this.setState(
      state => ({
        isOpen: !state.isOpen,
        activeField,
      }),
      () => {
        const activeEl = document.getElementById(activeField);
        if (activeEl) {
          this.selectDropdown.scrollTop = activeEl.offsetTop - 80;
        }
      }
    );
  }

  updateField(value) {
    // const { input } = this.props;
    this.props.input.onChange(value);
    this.setActiveField(value);
    this.setState({ touchedOverride: true });
  }

  render() {
    const {
      cb,
      options,
      input,
      label,
      id,
      required,
      hideFormGroup,
      hideEmpty,
      selectText,
      maxHeight,
      tooltip,
      meta: { touched, error },
      placeholder,
    } = this.props;
    const { isOpen, activeField, touchedOverride } = this.state;

    return (
      <div
        className={`${hideFormGroup ? '' : 'form-group'} ${
          (touched || touchedOverride) && error ? 'has-error' : ''
        }`}
      >
        {label && (
          <label htmlFor={id}>
            {tooltip ? (
              <span>
                {label} {required && <span className="text-danger danger-asterisk">*</span>}
              </span>
            ) : (
              <span>
                {label} {required && <span className="text-danger danger-asterisk">*</span>}
              </span>
            )}
          </label>
        )}
        <div
          id={id}
          className={`scroll-select ${(touched || touchedOverride) && error ? 'has-error' : ''} ${
            tooltip ? 'with-tooltip' : ''
          }`}
          data-tooltip={tooltip}
          onClick={() => {
            this.toggleDropdown();
          }}
        >
          <div className="btn btn-dropdown btn-boxed">
            {input.value === '' ? (
              <span className="scroll-value">{placeholder || 'Select...'}</span>
            ) : (
              <span className="scroll-value">
                {this.findLabel(input.value) || placeholder || 'Select...'}
              </span>
            )}
            {isOpen ? (
              <i className="zolaicon zolaicon-chevron-up" />
            ) : (
              <i className="zolaicon zolaicon-chevron-down" />
            )}
          </div>
          <div
            className="scroll-select-dropdown"
            style={{
              display: isOpen ? 'block' : 'none',
              maxHeight: maxHeight ? `${maxHeight}px` : '',
            }}
            ref={selectDropdown => {
              this.selectDropdown = selectDropdown;
            }}
          >
            {!hideEmpty && (
              <div
                id={`${input.name}-empty`}
                className={`scroll-select-option ${
                  activeField === `${input.name}-empty` ? 'scroll-select-active' : ''
                }`}
                onClick={() => this.updateField('')}
                onMouseEnter={() => this.setActiveField(`${input.name}-empty`)}
              >
                {selectText || 'Select...'}
              </div>
            )}
            {options.map(opt => (
              <div
                id={opt.value}
                key={opt.value}
                className={`scroll-select-option ${
                  activeField === opt.value ? 'scroll-select-active' : ''
                }`}
                onClick={() => {
                  this.updateField(opt.value);
                  if (cb) cb(opt.value);
                }}
                onMouseEnter={() => this.setActiveField(opt.value)}
              >
                {opt.title}
              </div>
            ))}
          </div>
        </div>
        {(touched || touchedOverride) && error && <span className="help-block">{error}</span>}
      </div>
    );
  }
}

RenderDropdownSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  hideFormGroup: PropTypes.bool,
  hideEmpty: PropTypes.bool,
  selectText: PropTypes.string,
  maxHeight: PropTypes.number,
  tooltip: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  cb: PropTypes.func,
  touchedOverride: PropTypes.bool,
};

function formatName(firstName, familyName) {
  if (firstName && familyName) {
    return `${firstName} ${familyName}`;
  }
  if (firstName) {
    return firstName;
  }
  if (familyName) {
    return familyName;
  }
  return 'Guest Name Unknown';
}

export class RenderGuestNameInput extends Component {
  constructor(props) {
    super(props);

    this.handleToggleEdit = this.handleToggleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateName = this.handleUpdateName.bind(this);
    this.isValid = this.isValid.bind(this);

    this.state = {
      editing: false,
      firstName: '',
      familyName: '',
    };
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (name === 'firstName') {
      this.setState({
        firstName: value,
      });
    } else if (name === 'familyName') {
      this.setState({
        familyName: value,
      });
    }
  }

  handleToggleEdit() {
    const { input } = this.props;
    this.setState(prevState => ({
      editing: !prevState.editing,
      firstName: input.value.firstName,
      familyName: input.value.familyName,
    }));
  }

  handleUpdateName(e) {
    e.preventDefault();
    const { input } = this.props;
    const { firstName, familyName } = this.state;
    const nextInput = { firstName, familyName };
    input.onChange(nextInput);
    this.setState({
      editing: false,
    });
  }

  isValid() {
    const { firstName, familyName } = this.state;
    if (firstName && familyName) {
      return true;
    }
    return false;
  }

  render() {
    const {
      input,
      meta: { touched, error },
    } = this.props;
    const { editing, firstName, familyName } = this.state;

    return (
      <div>
        {editing ? (
          <div className="form-group edit-guest-name">
            <h4>Your Guest</h4>
            <div className="margin-bottom">
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-6">
                  <div
                    className={`first-name${
                      touched && error && error.firstName ? ' has-error' : ''
                    }`}
                  >
                    <input
                      name="firstName"
                      type="text"
                      value={firstName || ''}
                      className="form-control"
                      onChange={this.handleChange}
                      placeholder="First Name"
                    />
                    {touched && error && error.firstName && (
                      <span className="help-block">Required</span>
                    )}
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6">
                  <div
                    className={`last-name${
                      touched && error && error.familyName ? ' has-error' : ''
                    }`}
                  >
                    <input
                      name="familyName"
                      type="text"
                      value={familyName || ''}
                      className="form-control"
                      onChange={this.handleChange}
                      placeholder="Last Name"
                    />
                    {touched && error && error.familyName && (
                      <span className="help-block">Required</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix">
              <div className="pull-right">
                <Link
                  role="button"
                  className="text-button margin-right-2x"
                  onClick={this.handleToggleEdit}
                >
                  cancel
                </Link>
                <ButtonV3 disabled={!this.isValid()} onClick={this.handleUpdateName}>
                  Update
                </ButtonV3>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`guest-name${
              touched && error && (error.firstName || error.familyName) ? ' has-error' : ''
            }`}
          >
            <h4
              className={
                touched && error && (error.firstName || error.familyName) ? 'has-error' : ''
              }
            >
              <span>{formatName(input.value.firstName, input.value.familyName)}</span>
              <span className="edit-guest-name-icon" onClick={this.handleToggleEdit}>
                <i className="glyphicon glyphicon-pencil" />
              </span>
            </h4>
            {touched && error && error.firstName && (
              <span className="help-block">First Name Required</span>
            )}
            {touched && error && error.familyName && (
              <span className="help-block">Family Name Required</span>
            )}
          </div>
        )}
      </div>
    );
  }
}

RenderGuestNameInput.propTypes = {
  input: PropTypes.shape({}),
  meta: PropTypes.shape({
    error: PropTypes.shape({}),
    touched: PropTypes.bool,
  }),
};

export const RenderSwitchButton = ({
  input,
  label,
  labelElem,
  className,
  id,
  type,
  onChangeAction,
  checkedOverride,
  disabled,
  meta: { touched, error },
}) => {
  const inputAttr = checkedOverride
    ? Object.assign({}, input, { checked: checkedOverride })
    : { ...input };
  return (
    <div className={`switch-field ${className} ${touched && error ? ' has-error' : ''}`}>
      <div className="switch-field-input-container">
        <input
          {...inputAttr}
          type={type}
          id={id}
          disabled={disabled}
          onChange={e => {
            if (onChangeAction) {
              onChangeAction();
            }
            input.onChange(e);
          }}
        />
        <label htmlFor={id}>
          {label && label}
          {labelElem && labelElem}
        </label>
      </div>
      {touched && error && <span className="help-block error-block">{error}</span>}
    </div>
  );
};

RenderSwitchButton.defaultProps = {
  className: '',
};

RenderSwitchButton.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
  }).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  labelElem: PropTypes.element,
  onChangeAction: PropTypes.func,
  checkedOverride: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};
